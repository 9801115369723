import Vue from "vue";
import VueResource from "vue-resource";
import AlarmService from "@/services/alarm.js";
import Auth from "@/services/auth.js";

Vue.use(VueResource);

const identity_number = 2;
const monitor = true; // set true to start monitoring alarms....
const baseurl = ""; //v1  set to "" after conversion
export default class EquipmentAlarmService {

  $proxy = new AlarmService();

  elapsed(di, info) {
    if (!di) {
      return new Date().getTime();
    } else {
      //console.log(((new Date()).getTime()-di)+"ms "+ (info||''));
    }
  }

  references() {
    return app.__vue__.$root.config.references;
  }

  alarmStatus(alarm) {
    let id =
      (alarm.alarm_current_state &&
        alarm.alarm_current_state.alarm_state &&
        alarm.alarm_current_state.alarm_state.id) ||
      0;
    let lst = this.references().alarm_states.filter(function (i) {
      return i.id == id;
    });
    return (lst.length && lst[0]) || null;
  }

  alarmCondition(alarm) {
    let id =
      (alarm.alarm_trigger_condition && alarm.alarm_trigger_condition.id) || 0;
    let lst = this.references().alarm_trigger_conditions.filter(function (i) {
      return i.id == id;
    });
    return (lst.length && lst[0]) || null;
  }

  alarmAdapter(alarm) {
    // let status = this.alarmStatus(alarm);
    let condition = this.alarmCondition(alarm);
    alarm.nome = alarm.nome || alarm.name;
    alarm.config_dado =
      alarm.config_dado || (alarm.data && alarm.data.id) || "";
    alarm.dado_nome = alarm.dado_nome || (alarm.data && alarm.data.name) || "";
    alarm.dado_valor_atual =
      alarm.dado_valor_atual ||
      (alarm.data &&
        alarm.data.current_value &&
        alarm.data.current_value.value) ||
      0;
    alarm.condicao =
      alarm.condicao || (condition && condition.comparison_operator) || "";
    alarm.limite = alarm.limite || alarm.limit || "";
    // alarm.nivel = alarm.nivel || alarm.level || "";
    alarm.estado = alarm.estado || alarm.alarm_current_state.state || false;
    alarm.pending_command =
      alarm.pending_command ||
      alarm.alarm_current_state.pending_command ||
      false;
    alarm.estado_reconhecimento =
      alarm.estado_reconhecimento ||
      (alarm.alarm_current_state &&
        alarm.alarm_current_state.acknowledgment_state);
    alarm.habilita_reconhecimento =
      alarm.habilita_reconhecimento || alarm.acknowledgment_enabled;
    alarm.alarm_state_identity_number =
      alarm.alarm_state_identity_number ||
      (alarm.alarm_current_state &&
        alarm.alarm_current_state.alarm_state &&
        alarm.alarm_current_state.alarm_state.number) ||
      0;
    alarm.alarm_state_identity_name =
      alarm.alarm_state_identity_name ||
      (alarm.alarm_current_state &&
        alarm.alarm_current_state.alarm_state &&
        alarm.alarm_current_state.alarm_state.name) ||
      "";
    alarm.datahora_ultimo_evento_transicao =
      alarm.datahora_ultimo_evento_transicao ||
      (alarm.alarm_current_state &&
        alarm.alarm_current_state.datetime_last_transition) ||
      "";
    alarm.datahora_ultimo_evento_ativacao =
      alarm.datahora_ultimo_evento_ativacao ||
      (alarm.alarm_current_state &&
        alarm.alarm_current_state.datetime_last_activation) ||
      "";
    alarm.datahora_ultimo_evento_reconhecimento =
      alarm.datahora_ultimo_evento_reconhecimento ||
      (alarm.alarm_current_state &&
        alarm.alarm_current_state.datetime_last_acknowledgment) ||
      "";
    return alarm;
  }

  alarmHistoryAdapter(alarm) {
    alarm.alarm_trigger_condition =
      alarm.alarm_trigger_condition ||
      alarm.alarme__alarm_trigger_condition ||
      null; // fix for api is mispelled
    let condition = this.alarmCondition(alarm);
    alarm.nome_alarme = (alarm.alarm && alarm.alarm.name) || "";
    alarm.datahora = alarm.date_time_alarm_activate;
    alarm.subevento =
      (alarm.system_sub_event && alarm.system_sub_event.name) || "";
    alarm.user = (alarm.user && alarm.user.email) || "";
    alarm.alarm_transition = (condition && condition.comparison_operator) || "";
    alarm.limite = alarm.alarme_limit;
    alarm.dado_valor = alarm.alarm_value;
    return alarm;
  }

  // validate duplicated method
  async fetch(query) {
    let self = this;
    return new Promise((resolve) => {
      this.$proxy.fetch(query).then(
        (response) => {
          if (response) {
            response = response.map(function (alarm) {
              return self.alarmAdapter(alarm);
            });
            resolve({ id: query.connector_id, alarms: response });
            return;
          }
          resolve(null);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }

  async history(query) {
    let self = this;
    return new Promise((resolve) => {
      let src = new AlarmService();
      src.history(query).then((response) => {
        if (response) {
          response = response.map(function (data) {
            return self.alarmHistoryAdapter(data);
          });
        }
        resolve(response);
      });
    });
  }

  async ack(alarm, entry) {
    let payload = {
      type_identity_number: identity_number,
      parameters: {
        alarm_configuration_id: alarm.id
      },
      user_data: {
        acknowledgement: entry
      }
    };
    let self = this;
    let auth = new Auth();
    return new Promise((resolve) => {
      let di = self.elapsed(); // utils
      let url = "comandos/";
      Vue.http.post(url, payload, auth.requestOptions()).then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          //console.log(error);
          let msg = ("body" in error && error.body && error.body.detail) || "";
          resolve(msg);
        }
      );
    });
  }

  getExtendedProperties(alarm) {
    let self = this;
    // TODO: it will make a request to the customer configuration
    let extended_properties = {
      notifications: {
        timeout: 5,
        groups: []
      }
    };
    if ("user_data" in alarm && alarm.user_data) {
      if (!("extended_properties" in alarm.user_data)) {
        alarm.user_data.extended_properties = extended_properties;
      }
    } else {
      alarm.user_data = {
        extended_properties: extended_properties
      };
    }
    return alarm.user_data.extended_properties;
  }

  async saveNotifications(alarm, notifications) {
    let self = this;
    return new Promise((resolve) => {
      let extendedProperties = self.getExtendedProperties(alarm);
      extendedProperties.notifications = notifications;
      let payload = {
        user_data: {
          extended_properties: extendedProperties
        }
      };
      self.saveAlarm(alarm, payload).then(function (ret) {
        resolve(ret);
      });
    });
  }

  async saveAlarm(alarm, payload) {
    let self = this;
    let auth = new Auth();
    return new Promise((resolve) => {
      let di = self.elapsed();
      let url = "alarmes/" + alarm.id + "/";
      Vue.http.patch(url, payload, auth.requestOptions()).then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          //console.log(error);
          let msg = ("body" in error && error.body && error.body.detail) || "";
          resolve(msg);
        }
      );
    });
  }

  async getAckStatus(query) {
    let self = this;
    let auth = new Auth();
    return new Promise((resolve) => {
      let di = self.elapsed(); // utils
      let url = `comandos/${query.id}/?format=json`;
      Vue.http.get(url, auth.requestOptions()).then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }

  save = this.$proxy.save;
  get = this.$proxy.get;
  remove = this.$proxy.remove;
  export = this.$proxy.export;
  import = this.$proxy.import;
  duplicate = this.$proxy.duplicate;
}
