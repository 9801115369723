/*
@brief: Single instance class that abstracts several real-time processing protocols (only mqtt enabled, although it supports gRPC implementation).
@author: Marco Aurelio Zoqui

terminal tests

mosquitto_sub -u "da39a3ee5e6b4b0d3255bfef95601890afd80709" -t 0677d1c8/connector_events

// actual connector client id
mosquitto_pub -i "0677d1c8" -u "da39a3ee5e6b4b0d3255bfef95601890afd80709" -t "0677d1c8/connector_events" -m '{"level":"debug","msg":"Connector(0677d1c8) online","client_id":"0677d1c8","created_at":"2024-05-24T15:05:30.109Z"}'

// unknown (random) connector client id
mosquitto_pub -u "da39a3ee5e6b4b0d3255bfef95601890afd80709" -t "0677d1c8/connector_events" -m '{"level":"debug","msg":"Connector(0677d1c8) online","client_id":"0677d1c8","created_at":"2024-05-24T15:05:30.109Z"}'

*/

const DEBUG = false;

const src = `${document.location.origin}/static/common/lib/js/mqttws31.js`;

const uuid = () => ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
  (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
);

const injectScript = async (src) => {
  return new Promise((resolve, reject) => {
    if (src) {
      if (src.indexOf("http") == 0) {
        const script = document.createElement("script");
        script.async = true;
        script.src = src;
        script.addEventListener("load", (e) => {
          resolve(e);
        });
        script.addEventListener("error", () =>
          reject("Error loading script.")
        );
        script.addEventListener("abort", () =>
          reject("Script loading aborted.")
        );
        document.head.appendChild(script);
      } else {
        document.body.insertAdjacentHTML("beforeend", src);
        resolve("Injected");
        // var template = document.createElement('template');
        // template.innerHTML = src.trim();
        // template.content.firstChild;
      }
    } else {
      reject("Empty?");
    }
  });
};

const RTP = () => {
  class MQTT {
    constructor(options) {
      let self = this;

      this._status = "";
      this._details = "";
      this._client = null;
      this._config = null;
      this._topics = null;
      this._msg_queue = [];
      this._timer = null;
      this._cleaning_up = false;

      this.onStatusChanged = options?.onStatusChanged || null;

      this.onMessage = options?.onMessage || null;

      this.setStatus("IDLE");
      // inject
      if (window.Paho) {
        setTimeout(() => {
          self.setStatus("READY"); // must but in another thread once it is running on constructor
        }, 0);
      } else {
        injectScript(src)
          .then(() => {
            if (window.Paho) {
              self.setStatus("READY");
            }
          })
          .catch((e) => {
            self.setStatus("ERROR", e);
          });
      }
      return this;
    }

    log(t) {
      if (DEBUG) console.log(`${new Date().getTime()} ${t}`);
    }

    setStatus(status, details) {
      this.log(status);
      this._status = status;
      this._details = details || "";
      if (this.onStatusChanged) {
        try {
          (async () => {
            this.onStatusChanged(this._status, this._details);
          })();
        } catch (e) {}
      }
      return this;
    }

    get status() {
      return this._status;
    }

    subscribe(topics, cb) {
      let _cb = cb || (() => {});
      const _run = (topic, callback) => {
        try {
          if (this._status !== 'CONNECTED') {
            callback();
            return;
          }
          this._client.subscribe(topic, {
            onSuccess: () => {
              this.log(`subscribed ${topic}`);
              if (!(this._topics || []).some((i) => i === topic)) {
                this._topics = this._topics || [];
                this._topics.push(topic);
              }
              callback();
            },
            onFailure: () => {
              this.log(`subscribe fail for ${topic}`);
              callback();
            }
          });
        } catch (error) {
          callback();
        }
      };
      const _s = (lst, _cb) => {
        lst = lst.filter((t) => !(this._topics || []).some((i) => i === t));
        let topic = lst.shift();
        if (topic === undefined) {
          _cb();
          return;
        }
        _run(topic, () => {
          _s(lst, _cb);
        });
      };
      this.__tsub = this.__tsub || _.throttle((topics, _cb) => {
        if (this._status !== 'CONNECTED') {
          _cb();
          return;
        }
        let lst = [...(topics || [])];
        _s(lst, _cb);
      }, 10);
      this.__tsub(topics, _cb);
    }

    unsubscribe(topics, cb) {
      let lst = [...(topics || [])];
      const _s = (topic) => {
        if (topic === undefined) {
          if (cb && typeof cb == "function") {
            cb();
          }
          return;
        }
        this._client.unsubscribe(topic, {
          onSuccess: () => {
            this.log(`unsubscribed ${topic}`);
            this._topics = (this._topics || []).filter((i) => i !== topic);
            _s(lst.shift());
          },
          onFailure: () => {
            this.log(`ERROR: unsubscribe fail for ${topic}`);
            // this._topics = (this._topics || []).filter((i) => i !== topic);
            _s(lst.shift());
          }
        });
      };
      _s(lst.shift());
    }

    unsubscribeAll(cb) {
      this.unsubscribe(this._topics, () => {
        this._topics = null;
        if (cb && typeof cb == "function") {
          cb();
        }
      });
    }

    subscribeOnlyOnTopics(topics, cb) {
      let lst = [...(topics || [])];
      let _cb = cb || (() => {});
      this.subscribe(lst, () => {
        lst = (this._topics || []).filter((topic) => !topic.match(/global_/) && lst.indexOf(topic) === -1);
        if (lst.length) {
          this.unsubscribe(lst, () => {
            _cb();
          });
        }
        else {
          _cb();
        }
      });
    }

    onConnected() {
      this._topics = null;
      this.setStatus("CONNECTED");
    }

    onFailure(e) {
      if (this._config && this._config.reconnectTimeout && this._client) {
        this.setStatus("ERROR", e);
        setTimeout(
          () => {
            this.connect();
          },
          this._config.reconnectTimeout,
          this
        );
      }
    }

    onDisconnected(e) {
      let self = this;
      this.setStatus("DISCONNECTED", e);
      if (this._client) {
        setTimeout(() => {
          if (this._client) {
            self.connect();
          }
        }, this._config.reconnectTimeout);
      }
    }

    onMessageArrived(info) {
      try {
        if (this._status !== 'CONNECTED') return;
        this.log(`onMessageArrived ${info.destinationName}`);
        const smsg = info ? info?.payloadString : "";
        // this.log(smsg);
        if (smsg) {
          let msg = JSON.parse(smsg);
          (async (msg) => {
            this.onMessage(msg, info);
          })((typeof msg === 'string' && msg == 'null') ? null : msg);
        }
      } catch (e) {
        // this.log(e)
      }
    }

    connect() {
      let self = this;
      let options = null;
      let map = {
        userName: "userName",
        password: "password",
        encrypted: "useSSL",
        keepAliveInterval: "keepAliveInterval"
      };
      for (let k in map) {
        if (k in this._config) {
          options = options || {};
          options[map[k]] = this._config[k];
        }
      }
      this.setStatus("CONNECTING");
      if (this._client) {
        if (!this._client.onMessageArrived) {
          this._client.onMessageArrived = (data) => {
            self.onMessageArrived(data);
          };
        }
        if (!this._client.onConnectionLost) {
          this._client.onConnectionLost = (e) => {
            self.onDisconnected(e);
          };
        }
        let cfg = {
          onSuccess: (data) => {
            self.onConnected(data);
          },
          onFailure: (e) => {
            self.onFailure(e);
          },
          ...(options || {})
        };
        this._client.connect(cfg);
      }
    }

    addMessage(topic, msg) {
      this._msg_queue.push({
        topic: topic,
        msg: msg
      });
      if (this._timer) return;
      let _cb = this.onMessageSent || (() => {});
      this._timer = setInterval(
        () => {
          if (this._timer && this._msg_queue.length) {
            try {
              if (this._cleaning_up) {
                this._cleaning_up = false;
                this._msg_queue = [];
                _cb(this._msg_queue.length);
                return;
              }
              if (this.status != "CONNECTED") return;
              let item = this._msg_queue.splice(0, 1)[0];
              if (item) {
                let message = new window.Paho.MQTT.Message(
                  JSON.stringify(item.msg)
                );
                message.destinationName = item.topic;
                this._client.send(message);
                _cb(this._msg_queue.length);
              }
            } catch (e) {
              console.log(e);
            } finally {
              if (!this._msg_queue.length) {
                clearInterval(this._timer);
                this._timer = null;
              }
            }
          }
        },
        25,
        this
      );
    }

    publish(topic, msg) {
      return new Promise((resolve) => {
        try {
          this.addMessage(topic, msg);
        } catch (e) {
          this.log(e);
        } finally {
          resolve((this.msg_queue || []).length);
        }
      });
    }

    disconnect() {
      this._cleaning_up = true;
      this._client.disconnect();
    }

    destroy() {
      if (this._client && this._client.isConnected()) {
        this._client.disconnect();
      }
      this._status = "";
      this._details = "";
      this._client = null;
      this._config = null;
      if (this.onStatusChanged) {
        this.onStatusChanged = () => {};
      }
      if (MQTT.instance) {
        delete MQTT.instance;
        MQTT.instance = null;
      }
    }

    setup(cfg) {
      this._topics = null;
      this._config = {
        host: (cfg || {})?.websocket?.host || "",
        port: (cfg || {})?.websocket?.port || "",
        encrypted: (cfg || {})?.websocket?.encrypted || false,
        reconnectTimeout: (cfg || {})?.websocket?.reconnectTimeout || 2000,
        clientId: (cfg || {})?.clientId || uuid(),
        userName: (cfg || {})?.userName || "",
        password: (cfg || {})?.password || ""
      };
      if (window.Paho) {
        this._client = new window.Paho.MQTT.Client(
          this._config.host,
          Number(this._config.port),
          this._config.clientId
        );
        this.connect();
      }
    }
  }

  return {
    // Instance factory method
    // it returns a singleton instance of the desired protocol (right now it returns MQTT object instance)
    // TODO: it might be able to select which instance from args (such as gRPC)
    MQTT(options) {
      if (!MQTT.instance) {
        MQTT.instance = new MQTT(options);
      }
      return MQTT.instance;
    }
  };
};

class VueRTP {
  constructor(config) {
    this.config = config;
    this.engines = {};
    this.$vm = null;
    this.enabled = true;
  }

  create($vm, name) {
    if (this.engines[name]) { console.log("Already created"); return; };
    if (!$vm || !this?.config?.websocket?.host || !this?.config?.websocket?.port ||
      !(this.config?.enabled ?? true)
    ) {
      console.log("VueRTP can not be initialized");
      return;
    }
    // MQTT setup
    if (!name || name == 'mqtt') {
      this.$vm = $vm;
      this.engines.mqtt = RTP().MQTT({
        onStatusChanged: (status) => {
          if (!this.enabled || !this.engines.mqtt || !this.$vm) return;
          if (this.$vm) {
            this.$vm.$store.commit("SET_BROKER_STATUS", status);
            window.dispatchEvent(new CustomEvent("mqtt:status", {
              detail: status
            }));
          }
          if (status == "READY") {
            this.engines.mqtt.setup({
              ...this.config,
              ...{
                topics: [] //Object.keys(this.mqttConnectorTopics).map((t) => `${t}/#`)
              }
            });
          }
        },
        onMessage: (msg, info) => {
          if (!this.enabled || !this.engines.mqtt || !this.$vm || !info) return;
          window.dispatchEvent(new CustomEvent("mqtt:message", {
            detail: { msg: msg, info: info }
          }));
        }
      });
    }
    // TODO WRTC setup
    // TODO WebSocker setup
  }

  destroy() {
    if (this.engines) {
      for (var name in this.engines) {
        this.engines[name].destroy();
        this.engines[name] = null;
      }
    }
    this.$vm = null;
    this.config = null;
  }

  get mqtt() {
    return this.engines.mqtt ?? null;
  }

  disable() {
    this.enabled = false;
  }

  enable() {
    this.enabled = true;
  }

  addListener(name, fn) {
    window.addEventListener(name, fn);
  }

  removeListener(name, fn) {
    window.removeEventListener(name, fn);
  }
};

export default {
  install(Vue, options) {
    if (Vue && Vue.prototype) {
      Vue.prototype.$rt = new VueRTP(options);
    }
  }
};
