import Vue from "vue";
import VueResource from "vue-resource";
import Auth from "@/services/auth.js";
import ConnectorService from "@/services/connector.js";
import DeviceService from "@/services/device.js";

Vue.use(VueResource);

// parse mqtt topic from a subject entry
// entry: connector | device | data
//   cut: -1 prefix | 1 = sufix | 0 = all
const mqttTopic = (entry, cut) => {
  if (!entry) return "";
  let v;
  if (entry?.device?.connector) {
    if (entry?.device?.label) {
      v = [
        entry?.device?.connector?.mqtt_topic_prefix || "connector",
        entry?.device?.label || "device",
        entry?.label || "data"
      ];
    } else {
      v = [
        entry?.device?.connector?.mqtt_topic_prefix || "connector",
        entry?.label || "data"
      ];
    }
  } else if (entry?.connector) {
    v = [
      entry?.connector?.mqtt_topic_prefix || "connector",
      entry?.label || "device"
    ];
  } else {
    v = [entry?.mqtt_topic_prefix || "connector"];
  }
  return (cut == -1 ? v.slice(0, -1) : cut == 1 ? v.slice(-1) : v).join("/");
};

export { mqttTopic };

export default class EquipmentService {
  getExtendedProperties(equipment) {
    let extended_properties = JSON.parse(
      JSON.stringify(
        (Vue.http.options.config &&
          Vue.http.options.config.equipment_extended_properties) ||
        {}
      )
    );
    if ("user_data" in equipment && equipment.user_data) {
      if ("extended_properties" in equipment.user_data) {
        for (var i in equipment) {
          if (i in equipment.user_data.extended_properties) {
            delete equipment.user_data.extended_properties[i];
          }
        }
      } else {
        equipment.user_data.extended_properties = extended_properties;
      }
    } else {
      equipment.user_data = {
        extended_properties: extended_properties
      };
    }
    if (equipment.base_model) {
      equipment.user_data.extended_properties = {};
      (((equipment && equipment.portal_data) ? equipment.portal_data.extended_properties : []) || []).forEach((prop) => {
        equipment.user_data.extended_properties[prop.name] = prop.value;
      });
    }
    return equipment.user_data.extended_properties;
  }

  parseNewEquipment(device) {
    let equipment = JSON.parse(JSON.stringify(device.connector));
    delete device.connector;
    equipment.devices = [device];
    return equipment;
  }

  equipmentAdapter(equipment, connectorService) {
    //new
    /*
    id: 1
    name: "TESTE MESSER ZAP91X"
    enabled: true
    location_name: "HI Tecnologia"
    location_address: null
    contract_id: 1
    process_area: {id: 6, name: "AREA PROCESSO CONNECTOR"}
    connector_type_id: 3
    protocol_id: 1
    serial_number: "910.05212"
    timezone: "America/Sao_Paulo"
    timezone_local_storage: "UTC"
    status: "Desconectado"
    is_connected: false
    number_active_alarms: 0
    has_active_alarms: false
    updates_datetime: false
    optimizes_frames: false
    portal_data: null
    user_data: {,…}
    */
    //old
    /*
   id: 1
    nome: "TESTE MESSER ZAP91X"
    numero_serie: "910.05212"
    localizacao: "HI Tecnologia"
    timezone: "America/Sao_Paulo"
    estado: "Desconectado"
    esta_conectado: false
    quantidade_alarmes_ativos: 0
    possui_alarmes_ativos: false
    user_data: {,…}
    contract_process_area: 
    */
    if (equipment.location_address) {
      for (var i in equipment.location_address) {
        equipment["location_address_" + i] = equipment.location_address[i];
      }
    }
    // remove extra dots from name attribute
    equipment.name = equipment.name.replace(/\./g, "");
    equipment.nome = "name" in equipment ? equipment.name : "";
    equipment.numero_serie =
      "serial_number" in equipment ? equipment.serial_number : "";
    equipment.localizacao =
      "location_name" in equipment ? equipment.location_name : "";
    // equipment.estado =
    //   "connector_status" in equipment ? equipment.connector_status.name : "";
    // equipment.esta_conectado =
    //   "is_connected" in equipment ? equipment.is_connected : false;
    // equipment.quantidade_alarmes_ativos =
    //   "number_active_alarms" in equipment ? equipment.number_active_alarms : 0;
    // equipment.possui_alarmes_ativos =
    //   "has_active_alarms" in equipment ? equipment.has_active_alarms : 0;
    // equipment.contract_process_area =
    //   "process_area" in equipment
    //     ? equipment.process_area
    //     : equipment.contract_process_area || null;
    // if there is no assigned screen get the default one
    if (connectorService) {
      equipment = connectorService.adapter(equipment);
    }
    return equipment;
  }

  parseDevices(devices) {
    let self = this;
    // zoqui's note: es6 has key and values properties but they can't be used due the lack of support on safari
    let connectorService = new ConnectorService();
    let inserted = {};
    // begin test only
    // window.is_connected = !(window?.is_connected||false);
    // end
    for (var i in devices) {
      var device = devices[i];
      if ("connector" in device && device.connector) {
        // begin test only
        //  device.connector.is_connected=window.is_connected;
        //  device.is_connected=window.is_connected;
        // end

        var equipId = device.connector.id;
        if (equipId in inserted) {
          delete device.connector;
          inserted[equipId].devices.push(device);
        } else {
          inserted[equipId] = self.equipmentAdapter(
            self.parseNewEquipment(device),
            connectorService
          );
        }
      }
    }
    let lst = [];
    for (var i in inserted) {
      lst.push(inserted[i]);
    }
    return lst;
  }

  async fetchDevices(query, URL) {
    let self = this;
    let srv = new DeviceService();
    return new Promise((resolve) => {
      srv.fetch(query, URL).then((data) => {
        //
        // count: 18
        // next_page: "https://api.dev.telemetria.webhi.com.br/rest/v1/devices/?contract_id=10&format=json&page=2&page_size=5"
        // next_page_number: 2
        // previous_page: null
        // previous_page_number: null
        // results: Array(5)
        //
        let result = {
          count: 0,
          next_page: null,
          next_page_number: null,
          previous_page: null,
          previous_page_number: null,
          url: null,
          results: [],
          equipmentList: null
        };
        if (data) {
          if ("results" in data && data.results.length) {
            result.count = data.count;
            result.next_page = data.next_page;
            result.next_page_number = data.next_page_number;
            result.previous_page = data.previous_page;
            result.previous_page_number = data.previous_page_number;
            result.results = data.results;
            result.url = data.url;
          } else if (data.length) {
            result.results = data;
          }
        }
        if (result.results) {
          var entries = self.parseDevices(result.results);
          var threads = (entries || []).map(function (item) {
            let extendedProperties = self.getExtendedProperties(item);
            Object.assign(item, extendedProperties);
            return item;
          });
          Promise.all(threads).then(function (equipmentList) {
            result.equipmentList = equipmentList;
            resolve(result);
          });
        } else {
          resolve(null);
        }
      });
    });
  }

  async fetchConnectorList(query) {
    let self = this;
    let srv = new ConnectorService();
    return new Promise((resolve) => {
      srv.fetch(query).then((data) => {
        if (data && data.length) {
          let items = [];
          for (var i in data) {
            let item = self.equipmentAdapter(data[i], srv);
            let extendedProperties = self.getExtendedProperties(item);
            Object.assign(item, extendedProperties);
            items.push(item);
          }
          resolve(items);
        } else {
          resolve(null);
        }
      });
    });
  }

  async fetchEquipment(query) {
    let self = this;
    let srv = new DeviceService();
    return new Promise((resolve) => {
      srv.fetch({ connector_id: query.id }).then(
        (data) => {
          if (data && data.length) {
            var entries = self.parseDevices(data);
            var threads = (entries || []).map(function (item) {
              let extendedProperties = self.getExtendedProperties(item);
              Object.assign(item, extendedProperties);
              return item;
            });
            Promise.all(threads).then(function (result) {
              resolve(result[0]);
            });
          } else {
            resolve(null);
          }
        },
        (error) => {
          resolve(null);
        }
      );
    });
  }

  async saveExtendedProperties(equipment, payload) {
    let self = this;
    let auth = new Auth();
    return new Promise((resolve) => {
      let url = "clps/" + equipment.id + "/";
      Vue.http.patch(url, payload, auth.requestOptions()).then(
        (response) => {
          if (response && response.body) {
            ////console.log(response.body);
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          //console.log(error);
          let msg = ("body" in error && error.body && error.body.detail) || "";
          resolve(msg);
        }
      );
    });
  }

  async cloneEquipment(equipment, payload) {
    let self = this;
    let auth = new Auth();
    return new Promise((resolve) => {
      let url = "clps/" + equipment.id + "/duplicar/";
      Vue.http.post(url, payload, auth.requestOptions()).then(
        (response) => {
          if (response && response.body) {
            resolve(response.body);
            return;
          }
          resolve(null);
        },
        (error) => {
          //console.log(error);
          let msg = ("body" in error && error.body && error.body.detail) || "";
          resolve(msg);
        }
      );
    });
  }

  async deleteEquipment(equipment) {
    let self = this;
    let auth = new Auth();
    return new Promise((resolve) => {
      let url = "clps/" + equipment.id + "/";
      Vue.http.delete(url, auth.requestOptions()).then(
        (response) => {
          resolve({ status: "ok" });
        },
        (error) => {
          //console.log(error);
          let msg = ("body" in error && error.body && error.body.detail) || "";
          resolve(msg);
        }
      );
    });
  }
}
