import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

function ID(str) {
  return Number(!str || (str + "").toUpperCase() == "NEW" ? 0 : str);
}

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      meta: {
        title: "page.sign_in"
      },
      component: () =>
        import(/* webpackChunkName: 'sign-in' */ "@/views/public/SignIn.vue"),
      props: function (route) {
        return {
          redirect_url: route.query.redirect_url || ""
        };
      }
    },
    {
      path: "/signin",
      name: "signin",
      meta: {
        title: "page.sign_in"
      },
      component: () =>
        import(/* webpackChunkName: 'sign-in' */ "@/views/public/SignIn.vue"),
      props: function (route) {
        return {
          redirect_url: route.query.redirect_url || ""
        };
      }
    },
    {
      path: "/signup/:plan_name?",
      name: "signup",
      meta: {
        title: "page.sign_up"
      },
      component: () =>
        import(/* webpackChunkName: 'sign-up' */ "@/views/public/SignUp.vue"),
      props: function (route) {
        return {
          planName: route.params.plan_name || ""
        };
      }
    },
    {
      path: "/auth/password_reset/:user_id/:token",
      meta: {
        title: "page.password_reset"
      },
      component: () =>
        import(
          /* webpackChunkName: 'user-password-reset' */ "@/views/public/UserPasswordReset.vue"
        ),
      props: function (route) {
        return {
          user_id: route.params.user_id || "",
          token: route.params.token || "",
          invite: false
        };
      }
    },
    {
      path: "/auth/invite/:user_id/:token",
      meta: {
        title: "page.password_reset"
      },
      component: () =>
        import(
          /* webpackChunkName: 'user-password-reset' */ "@/views/public/UserPasswordReset.vue"
        ),
      props: function (route) {
        return {
          user_id: route.params.user_id || "",
          token: route.params.token || "",
          invite: true
        };
      }
    },
    {
      path: "/auth/password_reset",
      meta: {
        title: "page.password_reset"
      },
      component: () =>
        import(
          /* webpackChunkName: 'user-password-reset' */ "@/views/public/UserPasswordReset.vue"
        )
    },
    {
      path: "/auth/activate/:activation_code?",
      meta: {
        title: "page.account_activation"
      },
      component: () =>
        import(
          /* webpackChunkName: 'user-activation' */ "@/views/public/UserActivation.vue"
        ),
      props: function (route) {
        return {
          activation_code: route.params.activation_code || ""
        };
      }
    },
    {
      path: "/view/:id",
      name: "PubView",
      meta: {
        title: "title"
      },
      component: () =>
        import(/* webpackChunkName: 'Screen' */ "@/views/public/PubView.vue"),
      props: function (route) {
        return {
          id: route.params.id || ""
        };
      }
    },
    {
      path: "/changelog",
      meta: { title: "page.changelog" },
      component: () =>
        import(
          /* webpackChunkName: 'dashboard-changelog' */ "@/views/public/Changelog.vue"
        )
    },
    {
      path: "/content_view",
      name: "content-view",
      meta: {
        title: "content_view"
      },
      component: () =>
        import(
          /* webpackChunkName: 'ContentView' */ "@/views/private/ContentView.vue"
        )
    },
    // dashboard
    {
      path: "/dashboard",
      component: () =>
        import(
          /* webpackChunkName: 'dashboard' */ "@/views/private/Dashboard.vue"
        ),
      redirect: "/dashboard/search",
      children: [
        {
          path: "search",
          meta: {
            title: "page.user_dashboard"
          },
          component: () =>
            import(
              /* webpackChunkName: 'dashboard-search' */ "@/views/private/DashboardSearch.vue"
            ),
          children: [
            {
              path: "table",
              meta: {
                title: "page.user_dashboard"
              },
              component: () =>
                import(
                  /* webpackChunkName: 'equipment-search-table' */ "@/views/private/EquipmentSearchTable.vue"
                )
            },
            {
              path: "map",
              meta: {
                title: "page.user_dashboard"
              },
              component: () =>
                import(
                  /* webpackChunkName: 'equipment-search-map' */ "@/views/private/EquipmentSearchMap.vue"
                )
            },
            {
              path: "list",
              meta: {
                title: "page.user_dashboard"
              },
              component: () =>
                import(
                  /* webpackChunkName: 'equipment-search-list' */ "@/views/private/EquipmentSearchList.vue"
                )
            },
            {
              path: "card",
              meta: {
                title: "page.user_dashboard"
              },
              component: () =>
                import(
                  /* webpackChunkName: 'equipment-search-card' */ "@/views/private/EquipmentSearchCard.vue"
                )
            },
            {
              path: "alarms",
              meta: {
                title: "page.user_dashboard"
              },
              component: () =>
                import(
                  /* webpackChunkName: 'equipment-search-alarm-table' */ "@/views/private/EquipmentSearchAlarmTable.vue"
                )
            },
            {
              path: "process",
              meta: {
                title: "page.user_dashboard"
              },
              component: () =>
                import(
                  /* webpackChunkName: 'equipment-search-process-area' */ "@/views/private/EquipmentSearchProcessArea.vue"
                )
            },
            {
              path: "custom",
              redirect: "/dashboard/search"
            },
            {
              path: "custom/:tabName",
              meta: {
                title: "page.custom"
              },
              component: () =>
                import(
                  /* webpackChunkName: 'dashboard-custom' */ "@/views/private/DashboardCustom.vue"
                )
            }
          ]
        },
        {
          path: "alarms/:equipmentId",
          meta: {
            title: "page.alarms"
          },
          component: () =>
            import(
              /* webpackChunkName: 'dashboard-alarms' */ "@/views/private/DashboardAlarms.vue"
            ),
          props: function (route) {
            return {
              equipmentId: Number(route.params.equipmentId)
            };
          }
        },
        {
          path: "edit",
          component: () =>
            import(
              /* webpackChunkName: 'dashboard-edit' */ "@/views/private/DashboardEdit.vue"
            ),
          props: function (route) {
            return {
              connector_id: ID(route.params.connector_id),
              device_id: ID(route.params.device_id),
              data_id: ID(route.params.data_id),
              alarm_id: ID(route.params.alarm_id)
            };
          },
          redirect: "edit/connector",
          children: [
            {
              path: "screen",
              meta: {
                title: "page.screens"
              },
              component: () =>
                import(
                  /* webpackChunkName: 'dashboard-edit-screen-picker.vue' */ "@/components/registration/dashboard-edit-screen-picker.vue"
                )
            },
            {
              path: "connector",
              meta: {
                title: "page.connectors"
              },
              component: () =>
                import(
                  /* webpackChunkName: 'dashboard-edit-connector-picker' */ "@/components/registration/dashboard-edit-connector-picker.vue"
                )
            },
            {
              path: "connector/:connector_id",
              name: "route-connector-form",
              meta: {
                title: "page.connectors"
              },
              component: () =>
                import(
                  /* webpackChunkName: 'dashboard-edit-connector-form' */ "@/components/registration/dashboard-edit-connector-form.vue"
                ),
              props: function (route) {
                return {
                  connector_id: ID(route.params.connector_id)
                };
              }
            },
            // data history usage statistics
            {
              path: "connector/:connector_id/data_history_usage",
              name: "route-connector-dashboard-history-usage",
              meta: {
                title: "page.history_usage_statistics"
              },
              component: () =>
                import(
                  /* webpackChunkName: 'dashboard-connector-history-usage-statistics' */ "@/components/dashboard-connector-history-usage-statistics.vue"
                ),
              props: function (route) {
                return {
                  connector_id: ID(route.params.connector_id)
                };
              }
            },
            // begin data selection straight from the connector
            {
              path: "connector/:connector_id/data",
              meta: {
                title: "page.data"
              },
              component: () =>
                import(
                  /* webpackChunkName: 'dashboard-edit-data-picker' */ "@/components/registration/dashboard-edit-data-picker.vue"
                ),
              props: function (route) {
                return {
                  connector_id: ID(route.params.connector_id),
                  device_id: 0
                };
              }
            },
            {
              path: "connector/:connector_id/alarm",
              meta: {
                title: "page.alarm"
              },
              component: () =>
                import(
                  /* webpackChunkName: 'dashboard-edit-alarm-picker' */ "@/components/registration/dashboard-edit-alarm-picker.vue"
                ),
              props: function (route) {
                return {
                  connector_id: ID(route.params.connector_id),
                  device_id: 0,
                  data_id: 0
                };
              }
            },
            {
              path: "connector/:connector_id/device",
              meta: {
                title: "page.devices"
              },
              component: () =>
                import(
                  /* webpackChunkName: 'dashboard-edit-device-picker' */ "@/components/registration/dashboard-edit-device-picker.vue"
                ),
              props: function (route) {
                return {
                  connector_id: ID(route.params.connector_id)
                };
              }
            },
            {
              path: "connector/:connector_id/device/:device_id",
              name: "route-device-form",
              meta: {
                title: "page.devices"
              },
              component: () =>
                import(
                  /* webpackChunkName: 'dashboard-edit-device-form' */ "@/components/registration/dashboard-edit-device-form.vue"
                ),
              props: function (route) {
                return {
                  connector_id: ID(route.params.connector_id),
                  device_id: ID(route.params.device_id)
                };
              }
            },
            {
              path: "connector/:connector_id/device/:device_id/data",
              meta: {
                title: "page.data"
              },
              component: () =>
                import(
                  /* webpackChunkName: 'dashboard-edit-data-picker' */ "@/components/registration/dashboard-edit-data-picker.vue"
                ),
              props: function (route) {
                return {
                  connector_id: ID(route.params.connector_id),
                  device_id: ID(route.params.device_id)
                };
              }
            },
            {
              path: "connector/:connector_id/device/:device_id/data/:data_id",
              name: "route-data-form",
              meta: {
                title: "page.data"
              },
              component: () =>
                import(
                  /* webpackChunkName: 'dashboard-edit-data-form' */ "@/components/registration/dashboard-edit-data-form.vue"
                ),
              props: function (route) {
                return {
                  connector_id: ID(route.params.connector_id),
                  device_id: ID(route.params.device_id),
                  data_id: ID(route.params.data_id)
                };
              }
            },
            {
              path:
                "connector/:connector_id/device/:device_id/data/:data_id/alarm",
              meta: {
                title: "page.alarms"
              },
              component: () =>
                import(
                  /* webpackChunkName: 'dashboard-edit-alarm-picker' */ "@/components/registration/dashboard-edit-alarm-picker.vue"
                ),
              props: function (route) {
                return {
                  connector_id: ID(route.params.connector_id),
                  device_id: ID(route.params.device_id),
                  data_id: ID(route.params.data_id)
                };
              }
            },
            {
              path:
                "connector/:connector_id/device/:device_id/data/:data_id/alarm/:alarm_id",
              name: "route-alarm-form",
              meta: {
                title: "page.alarms"
              },
              component: () =>
                import(
                  /* webpackChunkName: 'dashboard-edit-alarm-form' */ "@/components/registration/dashboard-edit-alarm-form.vue"
                ),
              props: function (route) {
                return {
                  connector_id: ID(route.params.connector_id),
                  device_id: ID(route.params.device_id),
                  data_id: ID(route.params.data_id),
                  alarm_id: ID(route.params.alarm_id)
                };
              }
            }
          ]
        },
        {
          path: "users",
          redirect: "access-control"
        },
        {
          path: "access-control",
          redirect: "access-control/users",
          meta: {
            title: "page.control_access"
          },
          component: () =>
            import(
              /* webpackChunkName: 'dashboard-users' */ "@/views/private/DashboardUsers.vue"
            ),
          children: [
            {
              path: "users",
              meta: {
                title: "page.control_access",
                tabTitle: "user",
                permission: "MembroDeContratoAcesso"
              },
              component: () =>
                import(
                  /* webpackChunkName: 'user-display' */ "@/components/access-control/user-display.vue"
                )
            },
            {
              path: "groups",
              meta: {
                title: "page.control_access",
                tabTitle: "permission_group",
                permission: "GrupoDeUsuariosAcesso"
              },
              component: () =>
                import(
                  /* webpackChunkName: 'user-group-display' */ "@/components/access-control/user-group-display.vue"
                )
            },
            {
              path: "process-areas",
              meta: {
                title: "page.control_access",
                tabTitle: "process_area",
                permission: "AreaDeProcessoAcesso"
              },
              component: () =>
                import(
                  /* webpackChunkName: 'process-area-display' */ "@/components/access-control/process-area-display.vue"
                )
            },
            {
              path: "privileges",
              meta: {
                title: "page.control_access",
                tabTitle: "privileges",
                permission: "MembroDeContratoAcesso"
              },
              component: () =>
                import(
                  /* webpackChunkName: 'privileges-display' */ "@/components/access-control/privileges-display.vue"
                )
            },
            {
              path: "privileges/permission-group",
              redirect: "privileges"
            },
            {
              path: "privileges/permission-group/:groupId",
              meta: {
                title: "page.control_access",
                tabTitle: "privileges"
              },
              component: () =>
                import(
                  /* webpackChunkName: 'privileges-display' */ "@/components/access-control/privileges-display.vue"
                ),
              props: ({ params, query }) => ({
                groupId: Number(params.groupId),
                users: query.users,
                state:
                  query.state ??
                  (query.users?.split?.(",")?.length == 1 ? "editing" : null)
              })
            },
            {
              path: "privileges/permission-group/:groupId/add-user",
              meta: {
                title: "page.control_access",
                tabTitle: "privileges"
              },
              component: () =>
                import(
                  /* webpackChunkName: 'privileges-display' */ "@/components/access-control/privileges-display.vue"
                ),
              props: (route) => ({
                groupId: Number(route.params.groupId),
                state: "adding"
              })
            },
            {
              path: "privileges/process-area",
              redirect: "privileges"
            },
            {
              path: "privileges/process-area/:processAreaId",
              meta: {
                title: "page.control_access",
                tabTitle: "privileges"
              },
              component: () =>
                import(
                  /* webpackChunkName: 'privileges-display' */ "@/components/access-control/privileges-display.vue"
                ),
              props: ({ params, query }) => ({
                processAreaId: Number(params.processAreaId),
                users: query.users,
                state:
                  query.state ??
                  (query.users?.split?.(",")?.length == 1 ? "editing" : null)
              })
            },
            {
              path: "privileges/process-area/:processAreaId/add-user",
              meta: {
                title: "page.control_access",
                tabTitle: "privileges"
              },
              component: () =>
                import(
                  /* webpackChunkName: 'privileges-display' */ "@/components/access-control/privileges-display.vue"
                ),
              props: (route) => ({
                processAreaId: Number(route.params.processAreaId),
                state: "adding"
              })
            },
            {
              path: "unauthorized",
              meta: {
                title: "unauthorized"
              },
              component: () => import("@/components/info-box.vue"),
              props: {
                preset: "unauthorized"
              }
            }
          ]
        },
        {
          path: "profile",
          meta: { title: "page.user_profile" },
          component: () =>
            import(
              /* webpackChunkName: 'dashboard-user-profile' */ "@/views/private/DashboardUserProfile.vue"
            )
        },
        {
          path: "settings/:initial?",
          meta: { title: "page.settings" },
          component: () =>
            import(
              /* webpackChunkName: 'dashboard-user-profile' */ "@/views/private/DashboardSettings.vue"
            ),
          props({ params, hash }) {
            return {
              initial: params.initial || "profile",
              innerTab: hash.slice(1)
            };
          }
        },
        {
          path: "plan",
          meta: { title: "page.user_plan" },
          component: () =>
            import(
              /* webpackChunkName: 'dashboard-user-plan' */ "@/views/private/DashboardUserPlan.vue"
            )
        },
        {
          path: "log",
          meta: { title: "page.event_history" },
          component: () =>
            import(
              /* webpackChunkName: 'dashboard-history' */ "@/views/private/DashboardHistory.vue"
            )
        },
        {
          path: "log/:connector_id",
          meta: { title: "page.event_history" },
          component: () =>
            import(
              /* webpackChunkName: 'dashboard-history' */ "@/views/private/DashboardHistory.vue"
            ),
          props({ params }) {
            return {
              connectorId: params.connector_id
                ? Number(params.connector_id)
                : null
            };
          }
        },
        {
          path: "page/:filename",
          component: () =>
            import(
              /* webpackChunkName: 'dashboard-page-container' */ "@/views/private/DashboardPageContainer.vue"
            ),
          props: function (route) {
            return {
              filename: route.params.filename
            };
          }
        },
        {
          path: "equipment/:equipmentId/data/edit",
          meta: {
            title: "page.data_value_editor"
          },
          component: () =>
            import(
              /* webpackChunkName: 'dashboard-data-editor' */ "@/views/private/DashboardDataEditor.vue"
            ),
          props: function (route) {
            return {
              equipmentId: Number(route.params.equipmentId)
            };
          }
        },
        {
          path: "equipment/:equipmentId/:panelName/edit",
          meta: {
            title: "page.alarm_notification_editor"
          },
          component: () =>
            import(
              /* webpackChunkName: 'dashboard-equipment-notification-editor' */ "@/views/private/DashboardEquipmentNotificationEditor.vue"
            ),
          props: function (route) {
            return {
              equipmentId: Number(route.params.equipmentId),
              panelName: route.params.panelName || ""
            };
          }
        },
        {
          path: "equipment/:equipmentId/:screenId?",
          meta: {
            title: "page.equipment_dashboard"
          },
          component: () =>
            import(
              /* webpackChunkName: 'dashboard-equipment' */ "@/views/private/DashboardEquipment.vue"
            ),
          props: function (route) {
            return {
              equipmentId: Number(route.params.equipmentId),
              screenId: route.params.screenId || ""
            };
          }
        },
        {
          path: "equipment/screen/:screenId",
          meta: {
            title: "page.equipment_dashboard"
          },
          component: () =>
            import(
              /* webpackChunkName: 'dashboard-equipment' */ "@/views/private/DashboardEquipment.vue"
            )
        },
        {
          path: "screen/edit/layout",
          redirect: "edit/screen" // it keeps backward compatibility for bookmarked url
        },
        {
          path: "screen/edit/:screenId?/:panelName?",
          meta: {
            title: "page.dashboard_editor"
          },
          component: () =>
            import(
              /* webpackChunkName: 'dashboard-editor' */ "@/views/private/DashboardScreenEditor.vue"
            ),
          props: function (route) {
            return {
              screenId: route.params.screenId || undefined,
              panelName: route.params.panelName || undefined
            };
          }
        },
        {
          path: "onlinee/config",
          meta: {
            title: "page.onlinee_config"
          },
          component: () =>
            import(
              /* webpackChunkName: 'dashboard-edit-device-picker' */ "@/components/registration/dashboard-edit-onlinee-config-form.vue"
            )
        },
        {
          path: "flows-admin",
          meta: {
            title: "Flows Admin"
          },
          component: () =>
            import(
              /* webpackChunkName: 'flows-admin' */ "@/components/flows/flows-admin.vue"
            )
        },
      ]
    },
    // terms-of-service
    {
      path: "/terms_of_service",
      name: "terms-of-service",
      meta: {
        title: "page.terms_of_service"
      },
      component: () =>
        import(
          /* webpackChunkName: 'TermsOfService' */ "@/components/terms-of-service.vue"
        )
    },

    {
      path: "/test/:cn",
      component: () =>
        import(
          `@/views/private/${document.location.pathname
            .match(/(\w+)\.vue$/g)
            .pop()}`
        )
    },
    { path: "*", redirect: "/dashboard/search" }
  ]
});
